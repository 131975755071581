/**
 * PageSubpage
 */

import React from 'react';

import { BaseWrap } from 'Containers/Layouts';
import StreamField from 'Components/StreamField';
import Wysiwyg from 'Components/Wysiwyg';
import Breadcrumbs from 'Components/Breadcrumbs';
import Hero from 'Components/Hero';
import SubMenu from 'Components/SubMenu';
import ButtonAI from 'Components/ButtonAI';
import s from './PageSubpage.module.scss';

class PageSubpage extends React.PureComponent {
    render() {
        const {
            breadcrumbs,
            hero,
            items,
            wysiwyg,
            id,
            submenu,
            ai,
        } = this.props;

        return (
            <article className={s['PageSubpage']}>
                {submenu &&
                    <SubMenu {...submenu}/>
                }
                <Breadcrumbs {...breadcrumbs} />
                <Hero {...hero} />

                {ai &&
                    <ButtonAI {...ai} modifiers={["Wrap", "PageSubpage"]} />
                }

                {wysiwyg && (
                    <div className={s['PageSubpage__Wysiwyg']}>
                        <Wysiwyg wysiwyg={wysiwyg} />
                    </div>
                )}

                {items && <StreamField items={items} currentPage={id} />}
            </article>
        );
    }
}

PageSubpage.defaultProps = {
    items: [],
};

export default BaseWrap(PageSubpage);
